
import axios from 'axios';

const getSelections = async () => await axios.get(`https://particules.uqam.ca/wp-json/wp/v2/selection?filter[custom_per_page]=500`).then(res => res.data)
const getSelectionByID = async (id) => await axios.get(`https://particules.uqam.ca/wp-json/wp/v2/selection?include[]=${id}`).then(res => res.data)

const getAuthors = async () => await axios.get(`https://particules.uqam.ca/wp-json/wp/v2/fiches?per_page=100`).then(res => res.data)
const getAuthorByID = async (id) => await axios.get(`https://particules.uqam.ca/wp-json/wp/v2/fiches?include[]=${id}`).then(res => res.data)

const getMedias = async () => await axios.get(`https://particules.uqam.ca/wp-json/wp/v2/media?filter[custom_per_page]=500`).then(res => res.data)
const getMediaByID = async (id) => await axios.get(`https://particules.uqam.ca/wp-json/wp/v2/media?include[]=${id}`).then(res => res.data)

export {
    getSelections,
    getSelectionByID,

    getAuthors,
    getAuthorByID,
    
    getMedias,
    getMediaByID,
}