import React, { Component } from 'react'

export default class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }
    openCloseMenu = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    render() {
        const { isOpen } = this.state
        return (
            <div className={`menu ${isOpen ? 'is-open' : 'is-close'}`}>
                <button className='hamburger' onClick={this.openCloseMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div className='menu-content' >
                    <ul className='menu-list'>
                        <li className='menu-item'><a href="http://particules.uqam.ca/a-propos-de-lexpo/">À Propos</a></li>
                        <li className='menu-item'><a href="http://particules.uqam.ca/autrices-auteurs-et-artiste/">Invité.e.s Spéciaux</a></li>
                    </ul>
                </div>
            </div>
        )
    }
}
